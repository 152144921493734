import React from 'react'
import sImg1 from '../../images/story/al_4.jpg'
import sImg2 from '../../images/story/al_5.jpg'
import sImg3 from '../../images/story/al_6.jpg'

import shape1 from '../../images/story/s-shape1.png'
import shape2 from '../../images/story/s-shape2.png'
import SectionTitle from '../SectionTitle'


const StorySection4 = (props) => {
    return (

        <section className="wpo-story-section-s4 section-padding" id="story">
            <div className="container">
                <SectionTitle subTitle={'Nuestra historia'} MainTitle={'¿Cómo pasó?'} />
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="story-timeline">
                            <div className="round-shape"></div>
                            <div className="row">
                                <div className="col col-lg-6 col-12">
                                    <div className="img-holder right-align-text left-site">
                                        <img src={sImg1} alt="" className="img img-responsive" />
                                        <div className="story-shape-img-1">
                                            <img src={shape1} alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-lg-6 col-12 text-holder">
                                    <span className="heart">
                                        <i className="fi flaticon-heart"></i>
                                    </span>
                                    <div className="story-text">
                                        <span className="date"><i className="ti-comments"></i></span>
                                        <h3>Cuando nos conocimos...</h3>
                                        <p>"Recordar cómo nuestros caminos se cruzaron por primera vez siempre
                                            llena mi corazón de alegría"</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col col-lg-6 col-12 order-lg-1 order-2 text-holder right-heart">
                                    <span className="heart">
                                        <i className="fi flaticon-heart"></i>
                                    </span>
                                    <div className="story-text right-align-text">
                                        <span className="date"><i className="ti-hand-point-right"></i><i className="ti-hand-point-left"></i></span>
                                        <h3>Nuestra historia comenzó...</h3>
                                        <p>"Cuando nuestras miradas se cruzaron entre la multitud, supe que algo especial estaba 
                                            naciendo. Juntos hemos navegado por aventuras y desafíos, fortaleciendo nuestro amor día tras día"</p>
                                    </div>
                                </div>
                                <div className="col col-lg-6 col-12 order-lg-2 order-1">
                                    <div className="img-holder right-align-img">
                                        <img src={sImg2} alt="" className="img img-responsive" />
                                        <div className="story-shape-img-1">
                                            <img src={shape2} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col col-lg-6 col-12">
                                    <div className="img-holder video-holder left-site">
                                        <img src={sImg3} alt="" className="img img-responsive" />
                                        <div className="story-shape-img-1">
                                            <img src={shape1} alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-lg-6 col-12 text-holder">
                                    <span className="heart">
                                        <i className="fi flaticon-heart"></i>
                                    </span>
                                    <div className="story-text">
                                        <span className="date"><i className="ti-flag-alt"></i></span>
                                        <h3>Nuestro día de compromiso...</h3>
                                        <p>"Quedará eternamente grabado en nuestras memorias como el inicio formal de nuestro viaje hacia el matrimonio.
                                        En un atardecer lleno de promesas, sellamos nuestro amor con un sí que resonará a lo largo de nuestras vidas"</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col offset-lg-6 col-lg-6 col-12 text-holder">
                                    <div className="round-shape-2"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default StorySection4;