import React from 'react';
import { Link } from 'react-router-dom'
import coupleImg1 from '../../images/couple/al_3.jpg'
import coupleImg2 from '../../images/couple/al_2.jpg'

const CoupleSection4 = (props) => {

    return (
        <section className="wpo-couple-section-s4 section-padding" id="couple">
            <div className="container-fluid">
                <div className="couple-area clearfix">
                    <div className="row align-items-center">
                        <div className="col col-xl-3 col-lg-4 col-12">
                            <div className="text-grid">
                                <h3>Lisbeth Díaz Montiel</h3>
                                <p>"Juntos, crearemos un hogar lleno de amor y risas, apoyándonos
                                    mutuamente en cada nuevo amanecer."</p>
                                <div className="social">
                                    <ul>
                                        <li><Link to="/"><i className="ti-music"></i></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col col-xl-6 col-lg-4 col-12">
                            <div className="middle-couple-pic-wrap">
                                <div className="middle-couple-pic">
                                    <div className="middle-couple-pic-inner">
                                        <img src={coupleImg2} alt="" />
                                    </div>
                                </div>
                                <div className="middle-couple-pic">
                                    <div className="middle-couple-pic-inner">
                                        <img src={coupleImg1} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col col-xl-3 col-lg-4 col-12">
                            <div className="text-grid">
                                <h3>Abner Olivos Gonzáles</h3>
                                <p>"Con emoción y amor, decido unir mi vida a la de mi amada, prometiendo compartir cada
                                    alegría y cada desafío."</p>
                                <div className="social">
                                    <ul>
                                        <li><Link to="/"><i className="ti-game"></i></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CoupleSection4;