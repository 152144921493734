import React from 'react'
import { Slide } from "react-awesome-reveal";
import SectionTitle from '../SectionTitle'
import sImg1 from '../../images/event/al_6.jpg'
import sImg2 from '../../images/event/8.jpg'
import sImg3 from '../../images/event/9.jpg'
import LocationMap from './Modal'


const Events = [
    {
        Simg: sImg1,
        title: 'Recepción',
        li1: 'Domingo, 28 de Julio. 2024 2:00 PM',
        li2: 'Allende, Guadalupe Ixcotla, 90804 Santa Ana Chiautempan, Tlax.',
        li3: 'Salón Cryda´s',
        animation: '1200',
    }

]

const EventSectionS3 = (props) => {
    return (
        <section className="wpo-event-section-s3 section-padding" id="event">
            <div className="container">
                <SectionTitle subTitle={'Nuestra boda'} MainTitle={'¿Cuándo & Dónde?'} />
                <div className="wpo-event-wrap">
                    <div className="row">
                        {Events.map((event, eitem) => (
                            <div className="col col-lg-12 col-md-12 col-12" key={eitem}>
                                <Slide direction="up" duration={event.animation} triggerOnce="true">
                                    <div className="wpo-event-item">
                                        <div className="wpo-event-img">
                                            <div className="wpo-event-img-inner">
                                                <img src={event.Simg} alt="" />
                                            </div>
                                        </div>
                                        <div className="wpo-event-text">
                                            <div className="title">
                                                <h2>{event.title}</h2>
                                            </div>
                                            <ul>
                                                <li>{event.li1}</li>
                                                <li>{event.li2}</li>
                                                <li>{event.li3}</li>
                                                <li><LocationMap /></li>
                                            </ul>
                                        </div>
                                    </div>
                                </Slide>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default EventSectionS3;